import { gql } from '@apollo/client';
import { formConfigFields, getFieldFragment } from '../../queries/form/form';
import { wizardStepConfig } from '../../queries/wizard/wizard';

const userInfo =`
reportedById
auditedById
reportedBy { 
    _id
    fullName 
}
auditedBy { 
    _id
    fullName 
}
`

export const saveChangesMutation = gql`mutation wizardSaveChanges($reportId:ID!,$changeSet:InputFormChangeSet!) {
    saved:wizardSaveChanges(reportId: $reportId,changeSet:$changeSet)

}
`

export const startSubmissionMutation = gql`mutation wizardStartSubmission($reportId:ID!,$settings:ReportingSettingsInput!) { 
      wizard: wizardStartSubmission(reportId: $reportId,settings: $settings) {
		_id
		settings {
            currency
            reportingScale
        }
        status
        steps {
            ${wizardStepConfig}
        }
        scopedData
        formulaSet {
            target
            formula
        }
        ${userInfo}
	  }

}
${getFieldFragment(true)}
`;

export const submitMutation = gql`mutation wizardSubmit($reportId:ID!) { 
    wizard: wizardSubmit(reportId: $reportId) {
        _id
        status
        ${userInfo}
    }
}
`;

export const restartSubmissionMutation = gql`mutation wizardReStartSubmission($reportId:ID!) { 
    wizard: wizardReStartSubmission(reportId: $reportId) {
        _id
        status
        ${userInfo}
    }
}
`;

export const startReviewMutation = gql`mutation wizardStartReview($reportId:ID!) { 
    wizard: wizardStartReview(reportId: $reportId) {
        _id
        status
        ${userInfo}
    }
}
`;

export const approveMutation = gql`mutation wizardApprove($reportId:ID!) { 
    wizard: wizardApprove(reportId: $reportId) {
        _id
        status
        ${userInfo}
    }
}
`;

export const rejectMutation = gql`mutation wizardReject($reportId:ID!) { 
    wizard: wizardReject(reportId: $reportId) {
        _id
        status
        ${userInfo}
    }
}
`;