import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ClientContext } from "../../../ui/containers/client";
import { useIntl } from "react-intl";
import { math } from "../../NewFormula/mathjs";
import { useReportScope } from "./useReportScope";
import { contextToFull } from "../../../ui/components/periodToText";
import { useMath } from "../../NewFormula/hooks/useMath";
import { useMutation } from "@apollo/client";
import { restartSubmissionMutation, saveChangesMutation } from "../../../graph/mutations/wizard";

// TODO, server does mapping
function pipedToPath(v) {
    
    let parts = v.split("|");
    parts = [parts[1],parts[0],...parts.slice(2)].join(".")
    if(parts.endsWith(".value")) {
        parts = parts.split(".");
        parts.pop();
        parts = parts.join(".")
    }
    return parts;
}

function createValidationSet(steps) {
    let validationSet = new Map();
    let configSteps = steps.filter(s=>s.config);
    for(let cfgStep of configSteps) {
        if(cfgStep?.config?.validations && cfgStep.config.validations.length>0) {
            let validations = cfgStep.config.validations.map((v)=> {
                let f = math.compile(v.formula);
                
                return {
                    ...v,
                    fields: v.dependencies.map(pipedToPath),
                    formula:{
                        evaluate:(scp)=> {
                            var m = math;
                            let res = f.evaluate(scp);
                            console.log("VALIDATE FN",res,v.formula);
                            return res;
                        }
                    }
                }
            })

            validationSet.set(cfgStep.config.key,(scp)=> {
                let errs=[];
                for(let v of validations) {
                    try {
                        //debugger;
                        let isValid = v.formula.evaluate(scp);
                        if(!isValid) {
                            errs.push({
                                config:cfgStep.config.key,
                                fields:v.fields,
                                status:v.validationType,
                                message:v.validationMessage?.value || " invalid "
                            })
                        } 
                    } catch(e) {
                        errs.push({
                            config:cfgStep.config.key,
                            fields:v.fields,
                            status:v.validationType,
                            message:"System err: " + e.message
                        })
                    }
                }
                return errs;
            })
        }
    }
    return validationSet;
}




// todo, cleanup and split
// children should not use the context directly
// always through use hooks, limitting behaviour/renders

export const useWizard = (reportInstance) => {

    console.log("useWizard","INIT");
    const intl = useIntl();
    const client = useContext(ClientContext);

    
    
    const { _id,status,config,settings, context,steps:allSteps,scopedData,formulaSet,
        reportedBy,
        auditedBy,
        previousOpenReports=[]
     } = reportInstance;
     console.log("useWizard","reportInstance",reportInstance);
    const { numeric } = context || {}

    const [restartSubmission,{loading:loadResartSubmission}] = useMutation(restartSubmissionMutation)
  
    const onReStartSubmission = useCallback(()=> {
        restartSubmission({
          variables:{
            reportId:_id
          }
        }).then(({data,errors})=> {

          setReadOnly(false);

        })
    },[restartSubmission,_id]);
   
    //temp: exclude non config steps
    // identification to move to system form rather than input reportable form
    // aka: not period specific per se
    const steps= allSteps.filter(s=>s.config);
    
    const info = {
        id:_id,
        title:config?.name?.value,
        clientName:client.name,
        numeric,
        contextText:contextToFull(context),
        reportedBy,
        auditedBy
    }
    const { calculate } = useMath(formulaSet);  
    
   
    const [scale,setScale] = useState(settings?.reportingScale || 1);
    const [currency,setCurrency] =useState(settings?.currency || "USD");

   
    
    
    const {scope,refreshScope} = useReportScope(numeric,scopedData,scale);
    
    const [saveChangesOfStep,{loading:savingChanges}] = useMutation(saveChangesMutation,{
        variables:{_id}
    });

    const [validations,setValidations] = useState([]);


    const validationSet = useMemo(()=>{
        console.log("create validation set")
        return createValidationSet(steps,setValidations)
    },[steps])

    const validateForm = useCallback((configKey)=>{
       
        let errs=[];
        if(validationSet.has(configKey)) {
            errs = validationSet.get(configKey)(scope);
            setValidations([...errs,...validations.filter(v=>v.config!=configKey)])
            
        }
       
        return errs.length==0;

    },[validations,setValidations,validationSet,scope]);

    const getFieldStatus = useCallback((fieldPath,fieldType,period)=> {
        let p = `${period}.${fieldPath}`
        let valids = validations.filter(v=>v.fields.includes(p));
        if(valids.length>0) {
            return {
                status:"error",
                message:valids.map(e=>e.message).join(", ")
            }
        } else {
            return null;
        }
    },[validations]);

    const hasValidationErrors = useCallback((configKey)=> {
        return validations.some(v=>v.config==configKey)
    },[validations])

    const [readOnly,setReadOnly] = useState(status=="created"||status=="submitted"||status=="in_review"||status=="approved")

    useEffect(()=> {
        setReadOnly(status=="created"||status=="submitted"||status=="approved"||status=="in_review")
    },[status])

    return {
        info,
        readOnly,
        editMode:()=> {
            setReadOnly(false);
        },
        resartSubmit:()=> {
            onReStartSubmission();
        },
        scope,
        intl,
        scale,
        currency,
        steps,
        client,
        status,
        validateForm,
        getFieldStatus,
        hasValidationErrors,
        calculate:()=> {
            calculate(scope);
        },
        changeSettings:({currency,scale})=> {
            setCurrency(currency);
            setScale(scale);
            //refreshScope()
        },
        saveChangesOfStep,
        refreshScope,
        previousOpenReports

       
    }
}