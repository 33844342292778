import React, { useCallback } from 'react';
import {connect} from 'react-redux';
import { push} from '../../../router';

import Loader from '../../components/loader/spinner';
import ReportsGridView from '../../components/grids/reports/incofin.jsx';
import {DashboardContainer, SmartLayout} from '../../components/dashboard';
import Pagination from '../../components/grids/custom/pagination';

import { loadReports, setCurrentPage, setFilter } from '../../../redux/actions/reports';
import { useQuery } from '@apollo/client';
import moment from 'moment';

/**
 * DESCRIPTION: overview of all report instances linked to a certain client (that is under the user his/her supervision)
 * ROUTE(S):
 *   => /incofin/reports
 *   => /incofin/reports/{reportId}
 * ACCESSIBLE BY:  Incofin-RD, Incofin-IM, Incofin-admin & admin
 * DATA: reportsQuery
 *          => all report instances (filtering will happen in a lower component if a reportId param is specified)
 *          => clients (that will be linked to the report instances)
 */

const itemsPerPage = 25;

function mapStateToProps(state) {
	return {
		loading: state.reports.loading,
		error: state.reports.error,
		reports: state.reports.data,
		totalReports: state.reports.totalReports,
		curPage: state.reports.curPage,
		filter: state.reports.filter
	};
}




const Reports = ({
	match,history
})=> {

	const onRowClick=useCallback((report)=> {
		let period = moment(report.numericContext.toString(),"YYYYMMDD").format("YYYY-MM")
		history.push(`/client/${report.clientKey}/reports/${report.key}/${period}`);
	},[history]);

	

	return <DashboardContainer>
	<SmartLayout>
		
				<div>
					<div style={{display: 'block'}}>
						<ReportsGridView reportId={match.params.reportId} itemsPerPage={itemsPerPage} showFilter={true}
														 fullMode={true} enableSort={false} 
														 onRowClick={onRowClick} />
						
					</div>

					
				</div>
	
	</SmartLayout>
</DashboardContainer>
}



export default Reports;