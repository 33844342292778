import React,{useCallback, useMemo, useRef, useState} from 'react';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import { connect } from 'react-redux';
import { push} from '../../../router';
import { graphql } from '@apollo/client/react/hoc';
import { FormattedMessage } from 'react-intl';

import AuthHelper from '../../../auth/AuthHelper';
import Loader from '../../components/loader/spinner';
import ReportsGridView from '../../components/grids/reports/clients';
//import ClientReportsGridView from '../../components/grids/reports/clients';
import Pagination from '../../components/grids/custom/pagination';
import ReportInstanceForm from '../../components/ReportInstanceForm';
import Panel from '../../components/dashboard/panel/panel';
import {DashboardContainer, SmartLayout} from '../../components/dashboard';

import { insertReportInstance } from '../../../graph/mutations/report';
import { loadReports, setCurrentPage, setFilter } from '../../../redux/actions/reports';
import { ReportingWizardDrawer } from '../../../features/NewWizard/ReportingWizard';
import moment from 'moment';


/**
 * DESCRIPTION: have a more detailed overview of all report instances (status, reporter, period, category, ...)
 * ROUTE(s):
 *  => /client/{clientId}/reports
 *  => /client/{clientId}/reports/{reportId}
 * ACCESSIBLE BY: Client, Incofin-RD, Incofin-IM, Incofin-admin & admin
 * DATA: reportsQuery
 * 					=> all report instances, filtering only happens when we receive all data and there is a reportId specified in the params
 * 				  => also clients (this is because we use the same query as in the Incofin reports page, and on that page, we do need these clients to link to report instances)
 */

function mapDispatchToProps(dispatch, props) {
	return {
		onReportClick: (report) => {
			dispatch(push(`/client/${props.match.params.clientId}/reports/${report.key}/${report.period}`))
		},
		fetchReports: (skip, limit, filter, keepPreviousResult, reportId, resetCurPage) => {
			dispatch(loadReports(props.match.params.clientId, reportId, skip, limit, filter, keepPreviousResult, resetCurPage));
		},
		setCurrentPage: (curPage, refetch, skip, limit, filter, keepPreviousResult, reportId) => {
			dispatch(setCurrentPage(props.match.params.clientId, reportId, curPage, refetch, skip, limit, filter, keepPreviousResult));
		},
		setFilter: (filter, limit, reportId) => {
			dispatch(setFilter(props.match.params.clientId, reportId, filter, limit));
		}
	};
}

function mapStateToProps(state) {
	return {
		language: state.session.user.language,
		//clients: state.session.clients,
		client: state.client,
		reportConfigs: state.data.reportConfigs,
		currency: state.session.currency
	};
}

const itemsPerPage = 5;
const itemsPerPageFull = 25;


const Reports = ({
	match,history,client,fullMode=true,language,reportConfigs
})=> {


	const [counter,setCounter] = useState(0);	
	const [creating,setCreating] = useState(false);
	

	const onAddReport = useCallback(()=> {
		setCreating(true);
	},[client]);

	const onCancelAdd = useCallback(()=> {
		//debugger;
		setCounter((s)=>{
			//debugger;
			return s+1; 
		});
		setCreating(false);
	},[]);


	console.log("COunTER",counter);
	//const formRef =useRef();

	//const [saving,setSaving] = useState(false);

	const reportKey = match.params.reportId;

	const isIncofinAdmin =  AuthHelper.isGranted('level', 'ADMIN');

	const [showReport,setShowReport] = useState(null);

	const onConfirmDelete = useCallback(()=> {

	})
	

	const actions = useMemo ( ()=>isIncofinAdmin ? [<button onClick={onAddReport}>
		<FormattedMessage id="app.buttons.add" />
	</button>] : [],[isIncofinAdmin])

	const onRowClick=useCallback((report)=> {
		let period = moment(report.numericContext.toString(),"YYYYMMDD").format("YYYY-MM")
		setShowReport({
			clientId:client._id,
			reportKey:report.key,
			periodKey:period
		});
		//history.push(`/client/${report.clientKey}/reports/${report.key}/${report.period}`);
	},[client]);

	/*<ReportsGridView 
							actions={actions} 
							clientId={client._id} 
							reportId={match.params.reportId} 
							itemsPerPage={fullMode ? itemsPerPageFull: itemsPerPage} 
							showFilter={true}
							fullMode={fullMode} 
							enableSort={false} 
							onRowClick={onRowClick} />*/
	

	
	return <DashboardContainer>
	<SmartLayout>
		
				<div>
					<div style={{display: 'block'}}>
						<ReportsGridView 
						counter={counter}
								actions={actions} 
								clientId={client._id} 
								reportId={match.params.reportId} 
								itemsPerPage={fullMode ? itemsPerPageFull: itemsPerPage} 
								showFilter={true}
								fullMode={fullMode} 
								enableSort={false} 
								onRowClick={onRowClick}/>
						{ isIncofinAdmin ?<> <div style={{height: '3em', marginTop: '2em'}}><button onClick={onAddReport}>
										<FormattedMessage id="app.buttons.add" />
									</button></div> 
									<ReportInstanceForm open={creating} onDone={onCancelAdd} onCancel={onCancelAdd} config={null}/>
									</>
									: null}
						
					</div>

					
				</div>
				
	</SmartLayout>
	{ showReport ? <ReportingWizardDrawer open={!!showReport} {...showReport} onClose={()=> setShowReport(null)}></ReportingWizardDrawer> : null }
	
</DashboardContainer>
}

export default connect(mapStateToProps)(Reports);