
import './incofin.scss';
import React, { useCallback } from 'react';
import {FormattedMessage} from 'react-intl';
import Panel from '../../dashboard/panel/panel.jsx';
import GridView,{GridviewNew} from '../../dashboard/gridview/gridview.jsx';
import SpanStatusComponent from '../../dashboard/gridview/components/span-status.jsx';
import moment from 'moment';
import {connect} from 'react-redux';
import {push} from '../../../../router';
import Filter from '../../../components/grids/custom/filter';
import {contextToCalendar} from "../../periodToText";
import { useHistory } from 'react-router-dom';
import { GraphTableServer } from '../../../components/table/GraphTable'

import reportsQuery from '../../../../graph/queries/incofin/reports';
/**
 * DESCRIPTION: show report instances on the Incofin's dashboard
 *   => different than the client report instances GridView because of different columns & data
 */



const DefaultProps = {
	itemsPerPage: 5
};


const gridDataMapper = (reports)=>{return reports.map((report) => {
	let overdue = moment().isAfter(moment(report.schedule.due));

	return {
		id: report._id,
		_id: report._id,
		configId: report.configId,
		clientId: report.client ? report.client._id : null,
		status: report.status === 'created' && overdue ? 'overdue' : report.status,
		client: report.client ? report.client.officialName : '',
		clientKey: report.client ? report.client.key : null,
		key: report.config?.key,
		category: report.config?.name?.value,
		periodName: contextToCalendar(report.context),
		period: report.context.text,
		numericContext: report.context.numeric,
		dueDate: moment(report.schedule.due).format('dddd MMMM DD YYYY'),
		overdue,
		reportedBy: report.reportedBy?.fullName,
	};
}); };

const columnMeta = [
	{
		"columnName": 'status',
		"displayName": <FormattedMessage id="grid.reports.columns.status"/>,
		"customComponent": SpanStatusComponent
	},
	{"columnName": 'client', "displayName": <FormattedMessage id="grid.reports.columns.client"/>},
	{"columnName": 'category', "displayName": <FormattedMessage id="grid.reports.columns.category"/>},
	{"columnName": 'periodName', "displayName": <FormattedMessage id="grid.reports.columns.period"/>},
	{"columnName": 'dueDate', "displayName": <FormattedMessage id="grid.reports.columns.due-date"/>},
	{"columnName": 'reportedBy', "displayName": <FormattedMessage id="grid.reports.columns.reporter"/>},
];

const rowMetadata = {
	'bodyCssClassName': function (rowData) {
		if (rowData.status === 'rejected' || (rowData.overdue && rowData.status !== 'approved')) return 'error';
	}
};

const IncofinReportsGridView =({
	onRowClick,
	showPager,
	enableSort,
	showFilter,
	itemsPerPage=DefaultProps.itemsPerPage,
	reports,
	onFilterChange,
	fullMode,
	showCustomFilter,
	reportId=null

})=> {
	const onRowClickCb = useCallback((row)=>{
		onRowClick(row.props.data);
	},[onRowClick]);
	const history = useHistory();
	const goToReports = useCallback(()=>{
		history.push('/incofin/reports')
	},[history]);



	return (
		<Panel title={<FormattedMessage id="grid.reports.title"/>} extraClassNames={'reports-panel'}>
			 <GraphTableServer qry={reportsQuery} 
        options={{
            fetchPolicy: "no-cache",
        }}
		vars={{configId:reportId,
			sort:[
				{
					field:"context.numeric",
					sort:"DESC"
				}
			]
		}}
        tableDataMapper={gridDataMapper}
        dataSelector={(d)=> { return d && d.reports && d.reports.results ? d.reports.results : []}}
        totalSelector={(d)=>d && d.reports && d.reports.pageInfo ? d.reports.pageInfo.total :0}
         columnMetadata={columnMeta} showFilter={true} showPager={fullMode} 
		 resultsPerPage={itemsPerPage}
								onRowClick={onRowClickCb} 
		 enableSort={enableSort}
        />
				
			{
				(!fullMode) ?
					<button className={'more-reports-button'} onClick={goToReports}>More</button> : null
			}
		</Panel>

	);

}


export default IncofinReportsGridView;
